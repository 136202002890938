@import 'react-tabs/style/react-tabs.css';

.react-tabs__tab-list {
  width: fit-content;
  border-collapse: separate;
  border-radius: 4px;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #ffcf36 0%, #fcb034 88.02%) border-box;
  overflow: hidden;
}

.react-tabs__tab:not(:first-of-type) {
  border-left: 2px solid transparent;
  border-image: linear-gradient(180deg, #ffcf36 0%, #fcb034 88.02%) 1;
}

.react-tabs__tab {
  border: 0px solid transparent;
  font-weight: bold;
  text-align: center;
  min-width: 120px;
  border-bottom: none;
  position: static;
  background: white;
}

.react-tabs__tab--selected {
  border-radius: 0px 0px 0 0;
  background-image: linear-gradient(180deg, #ffcf36 0%, #fcb034 88.02%);
}
