@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body * {
    @apply text-base;
    @apply font-sarabun;
  }
}

.ss-dropdown .ss-select-selection-placeholder {
  color: #9ca3af;
}

.ss-select-dropdown .ss-select-item-empty {
  background-color: white;
  padding: 0.25rem 1rem;
}

.react-toggle--checked .react-toggle-track {
  background-color: #f68809 !important;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #f68809 !important;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}
